@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom styles for Tremor components */
.tremor-Card-root {
  @apply shadow-md;
}

.tremor-Button-root {
  @apply transition-colors duration-200;
}

.tremor-TextInput-root {
  @apply focus:ring-2 focus:ring-blue-500;
}

/* Custom styles for navigation */
.nav-link {
  @apply hover:text-blue-500 transition-colors duration-200;
}

.nav-link.active {
  @apply text-blue-600 border-blue-500;
}

/* Dashboard specific styles */
.status-indicator {
  @apply w-3 h-3 rounded-full;
}

.status-indicator.running {
  @apply bg-green-500;
}

.status-indicator.stopped {
  @apply bg-red-500;
}

/* Custom scrollbar styles */
.overflow-y-auto {
  scrollbar-width: thin;
  scrollbar-color: rgba(203, 213, 225, 1) rgba(241, 245, 249, 1);
}

.overflow-y-auto::-webkit-scrollbar {
  width: 6px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: rgba(241, 245, 249, 1);
  border-radius: 3px;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: rgba(203, 213, 225, 1);
  border-radius: 3px;
  border: none;
}

.overflow-y-auto::-webkit-scrollbar-thumb:hover {
  background-color: rgba(148, 163, 184, 1);
}

/* Ensure text wrapping */
.break-words {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

/* Timestamp styles */
.whitespace-nowrap {
  white-space: nowrap;
}

/* Settings form styles */
.settings-form label {
  @apply block text-sm font-medium text-gray-700 mb-1;
}

.settings-form input {
  @apply w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm 
    focus:outline-none focus:ring-blue-500 focus:border-blue-500;
}

.settings-form button {
  @apply w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 
    transition-colors duration-200;
}

/* Recent activities styles */
.activity-item {
  @apply py-3 border-b border-gray-200 last:border-b-0;
}

.activity-time {
  @apply text-sm text-gray-500;
}

/* Stats card styles */
.stats-card {
  @apply bg-white rounded-lg shadow p-4;
}

.stats-value {
  @apply text-2xl font-bold text-gray-900;
}

.stats-label {
  @apply text-sm text-gray-500;
}

/* Custom styles cho dropdown */
.tremor-Select-root {
  background-color: white;
}

.tremor-SelectItem-root {
  background-color: white;
}

.tremor-SelectItem-root:hover {
  background-color: #f3f4f6;
}

.tremor-SelectPanel-root {
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.log-metadata {
  @apply mt-1 pl-4 text-xs text-gray-500;
}

.log-metadata-key {
  @apply font-medium text-gray-600;
}

.log-metadata-value {
  @apply font-mono break-all;
}

.log-metadata-object {
  @apply whitespace-pre-wrap bg-gray-50 p-1 rounded;
}